const propertyData = {
    "url": "http://4737sedylanway.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "4737 SE Dylan Way", "city": "Hillsboro", "state":"Oregon", "zipcode": "97123"},
    "display_address": "34737 SE Dylan Way",
    "property": {
      "listing_price": "$349,888",
      "bedrooms": "3",
      "baths": "2.5",
      "sq_feet": "1700",
      "lot_size": "1742",
      "rmls": "20081880",
      "built_in": "2013",
      "blurb": "Welcome home! This end-unit townhome has it all! Slab granite, 3.5” trim, maple cabinetry, new premium laminate throughout. Newer flooring throughout the home. New interior and exterior paint. Spacious master bedroom with huge WI-closet. 3rd bedroom on lower level can be used as flex space with slider to back patio & fenced yard. A/C. Low HOA fees. Beautifully maintained.",
      "ammenities": [
        
      ]
    },
    "image_count": 27,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.606431410331!2d-122.93733068444072!3d45.518000779101584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950fefe2d49e27%3A0x6d27e3c03100ad6e!2s4737%20SE%20Dylan%20Way%2C%20Hillsboro%2C%20OR%2097123!5e0!3m2!1sen!2sus!4v1582782330756!5m2!1sen!2sus",
        "youtube":"MmaiGPUsak4"
    }
  }

export default propertyData;

